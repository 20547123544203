<template>
  <div class="content_body WXMiniProgram">

    <el-card shadow="never" style="border:none">
      <!-- <div slot="header">
        <span class="font_16 color_333">微信小程序</span>
      </div> -->

      <div v-if="!miniProgramInfo" class="dis_flex flex_dir_row">
        <el-card class="position_relative" shadow="hover" style="height:260px;width:260px;" :body-style="card_body_style()">
          <el-image class="martp_20" style="width: 50px; height: 50px" :src="require('@/assets/img/MiniProgramlog.png')"></el-image>
          <p class="font_12 color_666 martp_20">已拥有小程序账号</p>
          <p class="font_12 color_666 martp_10 text_center">点击下方按钮<br>在新窗口中进行授权操作<br> </p>

          <el-button class="position_absolute " type="primary" size="small" style="width:120px;margin-top:20px;bottom:20px" @click="bindingClick"> 立即授权 </el-button>
        </el-card>

        <el-card class="position_relative marlt_20" shadow="hover" style="height:260px;width:260px;" :body-style="card_body_style()">
          <el-image class="martp_20" style="width: 50px; height: 50px" :src="require('@/assets/img/MiniProgramlog_dis.png')"></el-image>
          <p class="font_12 color_666 martp_20">还没有小程序账号</p>
          <p class="font_12 color_666 martp_10 pad_0_15">点击下方按钮前往微信公众平台注册（目前仅支持「企业」类型的主体）；<br>注册成功后，再进行授权操作即可</p>
          <el-button class="position_absolute " size="small" style="width:120px;margin-top:20px;bottom:20px" @click="miniProgramRegister"> 小程序官方注册 </el-button>
        </el-card>
      </div>
      <div v-else>
        <!-- <div class="tip martp_10">小程序版本信息</div> -->

        <el-form :model="miniProgramInfo" class="WeixinInfo" label-width="100px">
          <!-- <el-form-item >
            <el-button class="marlt_20" size="small">访问小程序</el-button>
          </el-form-item>
          <el-form-item label="自动更新：">有新版本时自动提交微信审核（若审核失败需手动更新）</el-form-item>
          <el-form-item label="先上版本：">3.0.0</el-form-item>
          <el-form-item label="更新时间：">2021-02-01 11：22：22</el-form-item>
          <el-form-item label="更新状态：">先上版本为最新版本</el-form-item> -->

          <!-- <div class="tip martp_10 color_666 font_14">小程序基本信息</div> -->

          <el-form-item label="小程序名称：">
            {{miniProgramInfo.authorizerInfo.nickName}}
            <el-button class="marlt_10" size="small" type="text" @click="bindingClick">重新授权</el-button>
            <el-button class="marlt_10" size="small" type="text" @click="MiniprogramRemoveBind">解除授权</el-button>
          </el-form-item>
          <el-form-item label="小程序头像：">
            <el-image :src="miniProgramInfo.authorizerInfo.headImg" style="width:150px;height:150px" :preview-src-list="[miniProgramInfo.authorizerInfo.headImg]"></el-image>
          </el-form-item>
          <!-- <el-form-item label="小程序码：">
            <el-image :src="miniProgramInfo.authorizerInfo.qrcodeUrl" :preview-src-list="[miniProgramInfo.authorizerInfo.qrcodeUrl]" style="width:150px;height:150px"></el-image>
          </el-form-item> -->

          <el-form-item label="介绍：">{{miniProgramInfo.authorizerInfo.signature}}</el-form-item>
          <el-form-item label="主体信息：">{{miniProgramInfo.authorizerInfo.principalName}}</el-form-item>
          <el-form-item label="微信认证：">{{miniProgramInfo.authorizerInfo.serviceTypeInfo==0?"微信认证":"未认证"}}</el-form-item>
          <el-form-item label="原始ID：">{{miniProgramInfo.authorizerInfo.userName}}</el-form-item>
          <el-form-item label="APPID：">{{miniProgramInfo.authorizationInfo.authorizerAppid}}</el-form-item>
          <el-form-item label="注册来源ID：">微信公众平台</el-form-item>

        </el-form>
      </div>
    </el-card>

    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
      <span>请在新窗口中完成小程序账号授权</span>

      <span slot="footer" class="dialog-footer">
        <el-button @click="authorizationError">授权失败，重试</el-button>
        <el-button type="primary" @click="authorizationSucceed">已成功设置</el-button>
      </span>
    </el-dialog>
  </div>

</template>

<script>
import API from "@/api/Marketing/App/WeiXinOpen";

export default {
  name: "WXMiniProgram",
  /**  引入的组件  */
  components: {},
  /**  Vue 实例的数据对象**/
  data() {
    return {
      dialogVisible: false,
      miniProgramInfo: null,
    };
  },
  /**  方法集合  */
  methods: {
    /**  跳转连接   */
    // getHrefURL(){
    //   let EnterpriseCode = localStorage.getItem("EnterpriseCode");
    //   let hrefURL = API.miniprogramComponent()+'?EnterpriseCode=' + EnterpriseCode;
    //   return hrefURL
    // },
    /**  立即授权小程序  */
    bindingClick() {
      let that = this;

      that.bindingClickMiniprogramAuthorizer();
    },
    /**  授权失败  */
    authorizationError() {
      let that = this;
      that.bindingClickMiniprogramAuthorizer();
    },
    /**  授权成功  */
    authorizationSucceed() {
      let that = this;
      that.dialogVisible = false;
      that.getMiniprogramAuthorizerInfo();
    },

    /**  小程序注册  */
    miniProgramRegister() {
      window.open("https://mp.weixin.qq.com/");
    },

    /**    */
    card_body_style() {
      return {
        display: "flex",
        padding: "0px",
        "flex-direction": "column",
        "align-items": "center",
      };
    },

    /**  跳转连接   */
    bindingClickMiniprogramAuthorizer() {
      let that = this;
      API.miniprogramComponent().then((res) => {
        if (res.StateCode == 200) {
          let url = res.Data.url;
          window.open(url);
          that.dialogVisible = true;
        } else {
          that.$message.error({
            message: "操作失败",
            duration: 2000,
          });
        }
      });
    },

    /**  解除小程序授权  */
    MiniprogramRemoveBind() {
      var that = this;
      that
        .$confirm(
          "解除授权微信号，会造成当前店铺的重要信息丢失（包括图文素材、自动回复、自定义菜单等），请谨慎操作",
          "提示",
          {
            confirmButtonText: "解除授权",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
        .then(() => {
          API.setMiniprogramRemoveBind()
            .then((res) => {
              if (res.StateCode == 200) {
                that.$message.error({
                  message: "已解除授权",
                  duration: 2000,
                });
                that.miniProgramInfo = null;
              } else {
                that.$message.error({
                  message: res.Message,
                  duration: 2000,
                });
              }
            })
            .finally(function () {
              that.dialogVisible = false;
            });
        })
        .catch(() => {
          that.$message({
            type: "info",
            message: "已取消操作",
          });
        });
    },
    /**  获取授权信息  */
    getMiniprogramAuthorizerInfo() {
      var that = this;
      API.getMiniprogramAuthorizerInfo()
        .then((res) => {
          if (res.StateCode == 200) {
            that.miniProgramInfo = res.Data;
          } else {
            // that.$message.error({
            //   message: res.Message,
            //   duration: 2000,
            // });
          }
        })
        .finally(function () {});
    },
  },
  /**  实例被挂载后调用  */
  mounted() {
    this.getMiniprogramAuthorizerInfo();
  },
};
</script>

<style  lang="scss">
.WXMiniProgram {
  border: none;
  .WeixinInfo {
    .el-form-item__label {
      font-size: 13px !important;
    }
    .el-form-item__content {
      font-size: 13px !important;
    }
    .el-form-item {
      margin-bottom: 0px;
    }
  }
}
</style>
